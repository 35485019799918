<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Close Partial Delivery</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    Write the reasons why you decide to close the partial
                    requests
                </v-col>
            </v-row>
            <v-form v-model="valid">
                <v-row no-gutters class="mb-2 mt-n5">
                    <v-col cols="12">
                        <v-textarea
                            v-model="notes"
                            rows="2"
                            hide-details
                            label="Notes"
                            prepend-icon="mdi-text"
                            required
                            :rules="[rules.required]"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="secondary" @click="close">
                Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                @click="closePartials"
                text
                color="error"
                :loading="loading"
                :disabled="!valid"
            >
                Close
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
export default {
    name: 'ClosePartial',
    props: {
        selectedBOM: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        notes: '',
        rules: {
            required: v => !!v || 'The value is required',
        },
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('close')
        },
        async closePartials() {
            try {
                this.loading = true
                const bom = await API.requestCloseBOMPartials({
                    id: this.selectedBOM.bomId,
                    itemCode: this.selectedBOM.code,
                    requestId: this.selectedBOM.id,
                    workOrderId: this.selectedBOM.workOrderId,
                    notes: this.notes,
                })
                this.$emit('replace', bom)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
